import Loadable from "@loadable/component";
import React from "react";

const EpisodeEllipsisMenu = Loadable(() => import("./EpisodeEllipsisMenu"));

function EpisodeEllipsisMenuAsyncLoading(props) {
  return null;
}

const EpisodeEllipsisMenuContainer = (props) => {
  return (
    <EpisodeEllipsisMenu
      {...props}
      fallback={<EpisodeEllipsisMenuAsyncLoading />}
    />
  );
};

export default EpisodeEllipsisMenuContainer;
