import Loadable from "@loadable/component";
import React from "react";

const PodcastEllipsisMenu = Loadable(() => import("./PodcastEllipsisMenu"));

function PodcastEllipsisMenuAsyncLoading(props) {
  return null;
}

const PodcastEllipsisMenuContainer = (props) => {
  return (
    <PodcastEllipsisMenu
      {...props}
      fallback={<PodcastEllipsisMenuAsyncLoading />}
    />
  );
};

export default PodcastEllipsisMenuContainer;
