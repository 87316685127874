import { makeSelectSpecificUserList } from "selectors/userlist";

import useReduxState from "hooks/useReduxState";

export function useUserList(listId) {
  const selectSpecificUserList = makeSelectSpecificUserList();

  const list = useReduxState(
    (state) => selectSpecificUserList(state, listId),
    [listId]
  );

  return list;
}

export default useUserList;
