import { Map } from "immutable";
import PropTypes from "prop-types";
import { forwardRef, memo, useMemo } from "react";

import EntitySharingLinks from "../Sharing/EntitySharingLinks";

import { getBaseUrl } from "constants/base";
import getUserListUrl from "utils/entity/getUserListUrl";

import useUserList from "hooks/useUserList";

const UserListSharingLinks = (props) => {
  const { listId, forwardedRef, eventProps: passedEventProps } = props;

  const list = useUserList(listId);

  const { info, eventProps } = useMemo(() => {
    const innerInfo = {
      url: `${getBaseUrl()}${getUserListUrl(list)}`,
      title: list.get("title"),
      description: list.get("description"),
    };
    const innerEventProps = {
      entity_id: list.get("id"),
      entity_type: "userlist",
      ...passedEventProps,
    };

    return {
      info: innerInfo,
      eventProps: innerEventProps,
    };
  }, [list, passedEventProps]);

  return (
    <EntitySharingLinks
      {...props}
      entity={list}
      entity_type="userlist"
      info={info}
      eventProps={eventProps}
      ref={forwardedRef}
    />
  );
};

UserListSharingLinks.propTypes = {
  list: PropTypes.instanceOf(Map),
  forwardedRef: PropTypes.object,
  eventProps: PropTypes.object,
};
UserListSharingLinks.defaultProps = {
  list: null,
  forwardedRef: null,
  eventProps: null,
};

const ForwardedWrapper = forwardRef((props, ref) => (
  <UserListSharingLinks forwardedRef={ref} {...props} />
));

ForwardedWrapper.displayName = "UserListSharingLinks";

export default memo(ForwardedWrapper);
