import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

export default {
  buttonContainer: {
    marginRight: "1rem",
    position: "relative",
  },
  buttonContainerMobile: {
    marginRight: "1rem",
  },
  lastButton: {
    marginRight: 0,
  },
  buttonInnerContainer: {
    position: "relative",
  },
  ratingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  ratingIconsContainer: {
    whiteSpace: "nowrap",
    margin: ".5rem 0",
  },
  buttonIcon: {
    fontSize: ".75rem",
    display: "flex",
  },
  buttonNoLabelMobilePlayIcon: {
    fontSize: ".8rem",
    margin: 0,
    padding: 0,
  },
  buttonIconNoLabel: {
    fontSize: "1.1rem",
  },
  buttonLabel: {
    ...gStyles.avalonBold,
    display: "block",
    fontSize: 12,
    marginLeft: ".375rem",
    whiteSpace: "nowrap",
    paddingRight: 2,

    [ScreenSizes.mdAndAbove]: {
      fontSize: 14,
    },
  },
};
