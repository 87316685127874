import Loadable from "@loadable/component";
import React from "react";

import LoadableLoading from "components/Common/LoadableLoading";

const BrandEllipsisMenu = Loadable(() => import("./BrandEllipsisMenu"));

function BrandEllipsisMenuAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const BrandEllipsisMenuContainer = (props) => {
  return (
    <BrandEllipsisMenu
      {...props}
      fallback={<BrandEllipsisMenuAsyncLoading />}
    />
  );
};

export default BrandEllipsisMenuContainer;
