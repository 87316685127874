import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";
import { useState } from "react";
import { followEntityPromise, unfollowEntityPromise } from "routines/follow";

import EllipsisMenuBackItem from "components/Buttons/EllipsisMenuButton/EllipsisMenuBackItem";
import EllipsisMenuButton from "components/Buttons/EllipsisMenuButton/EllipsisMenuButton";
import EllipsisMenuItem from "components/Buttons/EllipsisMenuButton/EllipsisMenuItem";
import { getModeratorModeCookie } from "components/UseIsModerator/UseIsModeratorProvider";
import UserListSharingLinks from "components/UserLists/UserListSharingLinks";

import modalActions from "actions/modals";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useRoutinePromises from "hooks/useRoutinePromises";
import { useStyles } from "hooks/useStyles";
import useUserHasInternalPermission from "hooks/useUserHasInternalPermission";

const baseStyles = {
  column: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
};

const ELLIPSIS_STEPS = ["nova", "share"];

const UserlistEllipsisMenu = (props) => {
  const { list, closeOnOutsideclick, isOwner } = props;

  const { styles } = useStyles(baseStyles, props);
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const userIsInternal = useUserHasInternalPermission();
  const isModerating = getModeratorModeCookie();

  const currentUserFollows = list?.getIn(["user_data", "follows"]);

  const analyticsVariables = useMemo(
    () => ({
      ellipsisMenuType: "UserlistEllipsisMenu",
      episode_id: list && list.get("id"),
    }),
    [list]
  );

  const { followEntity, unfollowEntity } = useRoutinePromises({
    followEntity: followEntityPromise,
    unfollowEntity: unfollowEntityPromise,
  });

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const preventDefault = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleShareClick = useCallback(
    (onChangeStep) => (e) => {
      sendGAEvent({
        listId: list?.get("id"),
        list_name: list?.get("title"),
        entity_type: "list",
        context: "ellipsis menu",
        componentContext: "UserlistEllipsisMenu",
        action: "shareModalOpen",
      });
      preventDefault(e);
      setSubMenuOpen(!subMenuOpen);
      onChangeStep("share")(e);
    },
    [subMenuOpen, list]
  );

  const createAList = useCallback(() => {
    sendGAEvent({
      ...analyticsVariables,
      action: "createListButtonClick",
      menuItem: "Share",
      object: "Header",
      context: "User List Page",
    });

    showModal("createList");
  }, [analyticsVariables, showModal]);

  const handleFollow = useCallback(
    (e) => {
      e.preventDefault();
      if (currentUserFollows) {
        unfollowEntity({
          entity_type: "userlist",
          entity_id: list.get("id"),
          entity: list,
        });
      } else {
        followEntity({
          entity_type: "userlist",
          entity_id: list.get("id"),
          entity: list,
        });
      }
    },
    [currentUserFollows, followEntity, list, unfollowEntity]
  );

  const renderContent = useCallback(
    (contentProps) => {
      const { currentStep, onBack, onChangeStep } = contentProps;

      switch (currentStep) {
        case "share":
          return (
            <div key="share" className={css(styles.column)}>
              <EllipsisMenuBackItem {...contentProps} onBack={onBack} />
              <UserListSharingLinks listId={list?.get("id")} />
            </div>
          );
        default:
          return (
            <div key="default" className={css(styles.column)}>
              <EllipsisMenuItem
                key="createAList"
                entity={list}
                entity_type="userlist"
                label="Create a list"
                onClick={createAList}
                onMouseDown={preventDefault}
                onMouseUp={preventDefault}
              />
              {userIsInternal && isModerating && (
                <EllipsisMenuItem
                  key="open-list-in-nova"
                  entity={list}
                  entity_type="nova"
                  label="Open in Nova"
                  to={`https://api.podchaser.com/nova/resources/list-resources/${list?.get(
                    "id"
                  )}`}
                  link
                  target="_blank"
                  anchor
                  onMouseDown={preventDefault}
                  onMouseUp={preventDefault}
                />
              )}
              {!isOwner && (
                <EllipsisMenuItem
                  key="follow"
                  entity={list}
                  entity_type="userlist"
                  label={currentUserFollows ? "Following" : "Follow"}
                  onClick={handleFollow}
                  onMouseDown={preventDefault}
                  onMouseUp={preventDefault}
                />
              )}
              <EllipsisMenuItem
                key="share"
                entity={list}
                entity_type="userlist"
                label="Share"
                onClick={handleShareClick(onChangeStep)}
                onMouseDown={preventDefault}
                onMouseUp={preventDefault}
              />
            </div>
          );
      }
    },
    [
      styles.column,
      list,
      createAList,
      userIsInternal,
      isModerating,
      isOwner,
      currentUserFollows,
      handleFollow,
      handleShareClick,
    ]
  );

  return (
    <EllipsisMenuButton
      renderContent={renderContent}
      steps={ELLIPSIS_STEPS}
      analyticsVariables={analyticsVariables}
      closeOnOutsideclick={closeOnOutsideclick}
      subMenuOpen={subMenuOpen}
      setSubMenuOpen={setSubMenuOpen}
    />
  );
};

UserlistEllipsisMenu.propTypes = {
  list: PropTypes.instanceOf(Map).isRequired,
  showEllipsisItems: PropTypes.array,
};

UserlistEllipsisMenu.defaultProps = {
  showEllipsisItems: null,
};

export default memo(UserlistEllipsisMenu);
