import Loadable from "@loadable/component";
import React from "react";

import LoadableLoading from "components/Common/LoadableLoading";

const NetworkEllipsisMenu = Loadable(() => import("./NetworkEllipsisMenu"));

function NetworkEllipsisMenuAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const NetworkEllipsisMenuContainer = (props) => {
  return (
    <NetworkEllipsisMenu
      {...props}
      fallback={<NetworkEllipsisMenuAsyncLoading />}
    />
  );
};

export default NetworkEllipsisMenuContainer;
