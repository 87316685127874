import Loadable from "@loadable/component";
import React from "react";

import LoadableLoading from "components/Common/LoadableLoading";

const CreatorEllipsisMenu = Loadable(() => import("./CreatorEllipsisMenu"));

function CreatorEllipsisMenuAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const CreatorEllipsisMenuContainer = (props) => {
  return (
    <CreatorEllipsisMenu
      {...props}
      fallback={<CreatorEllipsisMenuAsyncLoading />}
    />
  );
};

export default CreatorEllipsisMenuContainer;
